<template>
  <div class="right-side items-center m-4 mr-6 text-xs">
    <button
      v-if="showHelp"
      class="filter-dropshadow relative inline-flex items-center px-2 py-2 rounded-t-md border border-gray-300 text-sm leading-5 font-medium bg-gray-300 text-gray-600 hover:text-gray-600 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
      @click.prevent="showHelpOverlay"
    >
      <fa-icon icon="question" />
    </button>
    <button
      class="filter-dropshadow relative inline-flex items-center px-2 py-2 ml-2 rounded-full text-sm leading-5 font-medium text-white hover:bg-gray-800 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150"
      :class="{ 'bg-gray-800 text-white': showSettingsMenu }"
      @click.prevent.stop="showSettings"
    >
      <fa-icon icon="layer-group" />
    </button>
    <button
      class="filter-dropshadow relative inline-flex items-center px-2 py-2 ml-2 rounded-full text-sm leading-5 font-medium text-white hover:bg-gray-800 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150"
      @click.prevent="toggleFullscreen"
    >
      <fa-icon
        v-if="!isFullscreen"
        icon="expand"
      />
      <fa-icon
        v-else
        icon="compress"
      />
    </button>
  </div>
</template>
<script>
export default {
  name: 'ViewerRightControls',

  props: ['isFullscreen', 'showSettingsMenu', 'showHelp'],

  methods: {
    showHelpOverlay () {
      this.$emit('show-help')
    },

    showSettings () {
      this.$emit('show-settings')
    },

    toggleFullscreen () {
      this.$emit('toggle-fullscreen')
    }

  }
}</script>
